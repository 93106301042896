<template>
  <section class="auth-section" v-if="1 == 2">
    <!--        {{ $store.state.user }}-->
    <div class="container mob-container text-center">
      <img src="/images/logo_h.png" class="logo" alt="logo"/>
      <!--            <LogoSvg class="logo"/>-->
      <h1>{{ $t('registration') }}</h1>
      <InputGroup label="Email" type="email" v-model="email"/>
      <InputGroup :label="$t('password')" v-model="password" type="password"/>
      <InputGroup :label="$t('name')" type="text" v-model="name"  @enter="register"/>
      <SelectGroup :floatinglabel="true" v-model="primary_role_id" :options="roles" title="title" :label="$t('role')"/>
      <div class="form-group">
        <button @click="register" class="btn-themed w-100">{{ $t('save') }}</button>
      </div>
      <div class="error" v-if="error">{{ error }}</div>
      <div class="mt-4">
        <router-link class="text-muted small text-underlined" to="/login">{{ $t('authorization') }}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import InputGroup from "@/views/parts/general/form/InputGroup"
import SelectGroup from "@/views/parts/general/form/SelectGroup"
// import LogoSvg from "@/assets/svg/logo.svg"
import AuthService from "@/services/auth.helper"
import { UsersService } from '@/services/api.service'
import moment from 'moment-timezone'
import '@/assets/sass/auth.scss'

export default {
  components: {
    // LogoSvg,
    InputGroup,
    SelectGroup
  },
  data() {
    return {
      email: this.$route.query.email ? this.$route.query.email : "",
      name: this.$route.query.name ? this.$route.query.name : "",
      password: "",
      error: "",
      primary_role_id: "",
      timezone: moment.tz.guess(),
      roles: [
        { id: 1, title: this.$t('teacher')  },
        { id: 4, title: this.$t('school')  }
      ]
    }
  },
  mounted() {

  },
  methods: {
    register() {
      let params = {
        email: this.email,
        name: this.name,
        primary_role_id: this.primary_role_id,
        password: this.password,
        timezone: this.timezone
      }
      UsersService.register(params).then(res => {
        let r = this.apiResponse(res)
        console.log(r)
        if(r.success) {
          let user = r.data
          if(user.api_token) {
            AuthService.login({
              expires_in: 43200*60,
              access_token: user.api_token,
              refresh_token: user.api_token,
            }, user)
            this.$store.commit('saveUser', { user: user, vm: this } )
            this.$nextTick(() => {
              this.$router.push('/')
            })
          }
        } else {
          this.error = r.data
        }
      }).catch(err => {
        console.log(err)
        this.error = this.$t("login_or_pass_incorrect")
      })
    }
  },
  metaInfo() {
    return {
      title: this.$t('registration'),
    }
  },
}
</script>


<style lang="scss" scoped>

</style>
